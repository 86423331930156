import { useStaticQuery, graphql } from 'gatsby'

const usePropertyQuery = () => {
  const { allDatoCmsProperty } = useStaticQuery(graphql`
    query PropertyQuery {
      allDatoCmsProperty (
        filter: {hideForLocale: {ne: true}}
      ) {
        nodes {
          id
          address
          addressComponents {
            buildingName
            city
            country
            level
            postCode
            region
            regionShort
            street
            streetNumber
            unit
          }
          allCarSpaces
          beds
          baths
          market
          propertyType
          price
          priceSearch
          permalink
          title
          for
          images {
            all {
              ...MediaItemProps
            }
            primary {
              ...MediaItemProps
            }
          }
          slug
          inspections {
            startDateTimeUtc
            endDateTimeUtc
          }
          soldDate(formatString: "DD MMM yyyy")
          hasAuction
          auctionDateTime
          auctionVenue
        }
      }
    }
  `)

  return allDatoCmsProperty.nodes
}

export default usePropertyQuery
