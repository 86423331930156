import React from 'react'
import { MediaItem } from '../../../plugins/gatsby-plugin-atollon'
import { Link } from '../../../plugins/gatsby-plugin-atollon'
import AddToCalendarHOC from 'react-add-to-calendar-hoc'
import moment from 'moment'
import {RightArrow} from "../icons";

const ATCDropdown = (args) => (
    <ul className='atc-dropdown'>
      { args.children.map((link, i) => (
          <li key={ i }>{ link }</li>
      )) }
    </ul>
)

const ATCWrapper = (args) => (
    <Link onClick={ args.onClick } className='atc-item' href='/'>
      <span dangerouslySetInnerHTML={ { __html: args.children } }/>
    </Link>
)

const Upcoming = (props) => {

  const renderAuction = () => {
    let title = props.address;
    if (!props.auctionDateTime) {
      return null;
    }
    const AddToCalendarDropdown = AddToCalendarHOC(ATCWrapper, ATCDropdown);
    let duration = 1;
    let auctionEvent = {
      description: `Auction @ ${title}`, duration, endDatetime: moment(props.auctionDateTime).add(1, "hours").format("YYYYMMDDTHHmmss"), location: title, startDatetime: moment(props.auctionDateTime).format("YYYYMMDDTHHmmss"), title: `${title} - Property Auction`,
    };
    return (
        <AddToCalendarDropdown
            buttonText={`
          <h4>${moment(props.auctionDateTime).format("dddd Do MMM")}</h4>
          <p>${moment(props.auctionDateTime).format("h:mm A")}</p>
          <p>${props.auctionVenue}</p>
        <div class='btn'>
          <span>Add to </span> Calendar
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.41659 15.5001H2.99992C2.55789 15.5001 2.13397 15.3245 1.82141 15.012C1.50885 14.6994 1.33325 14.2755 1.33325 13.8335V3.83346C1.33325 3.39143 1.50885 2.9675 1.82141 2.65494C2.13397 2.34238 2.55789 2.16679 2.99992 2.16679H12.9999C13.4419 2.16679 13.8659 2.34238 14.1784 2.65494C14.491 2.9675 14.6666 3.39143 14.6666 3.83346V8.00012M11.3333 0.500122V3.83346M4.66659 0.500122V3.83346M1.33325 7.16679H14.6666M11.3333 13.8335H16.3333M13.8333 11.3335V16.3335" stroke="black" stroke-linecap="square"/>
          </svg>
        </div>
        `}
            event={auctionEvent}
        />
    );
  };

  const renderInspection = (inspection) => {
    let title = props.address
    const AddToCalendarDropdown = AddToCalendarHOC(ATCWrapper, ATCDropdown)
    let duration = 0.5
    let auctionEvent = {
      description: `Inspection @ ${ title }`,
      duration,
      endDatetime: moment(inspection.startDateTimeUtc).add(30, "minutes").format('YYYYMMDDTHHmmss'),
      location: title,
      startDatetime: moment(inspection.startDateTimeUtc).format('YYYYMMDDTHHmmss'),
      title: `${ title } - Property Inspection`,
    }
    return (
        <AddToCalendarDropdown buttonText={ `<h4>${moment(inspection.endDateTimeUtc).format('dddd D MMMM')}</h4><p>${moment(inspection.startDateTimeUtc).format('h:mm A')} – ${moment(inspection.startDateTimeUtc).add(30, "minutes").format('h:mm A')}</p>
        <div class='btn'>
          <span>Add to </span> Calendar
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.41659 15.5001H2.99992C2.55789 15.5001 2.13397 15.3245 1.82141 15.012C1.50885 14.6994 1.33325 14.2755 1.33325 13.8335V3.83346C1.33325 3.39143 1.50885 2.9675 1.82141 2.65494C2.13397 2.34238 2.55789 2.16679 2.99992 2.16679H12.9999C13.4419 2.16679 13.8659 2.34238 14.1784 2.65494C14.491 2.9675 14.6666 3.39143 14.6666 3.83346V8.00012M11.3333 0.500122V3.83346M4.66659 0.500122V3.83346M1.33325 7.16679H14.6666M11.3333 13.8335H16.3333M13.8333 11.3335V16.3335" stroke="black" stroke-linecap="square"/>
          </svg>
        </div>` } event={ auctionEvent }/>
    )
  }

  const stableDateUnix = props.isInspection ? new Date(props.inspectionCutoffDate).getTime() : 0;

  return (
      <div className='card card--upcoming' data-sal='slide-up' data-sal-duration='1000'>
        <div className='card--upcoming__wrapper'>
          <div className='card--upcoming-auction card--upcoming-auction--mobile'>
            <div className='card--upcoming-auction__auction'>
              <div className='card--upcoming-auction__rows'>
                {props.type === 'inspection' && props.inspections?.length > 0 && (<div className='card--upcoming-auction__row'>
                  <h3>Next Inspection</h3>
                  {props.inspections.filter(x => {
                    return new Date(x.startDateTimeUtc).getTime() > stableDateUnix
                  }).sort((a, b) => {
                    return new Date(a.startDateTimeUtc).getTime() - new Date(b.startDateTimeUtc).getTime()
                  }).slice(0, 1).map((x => (<>{renderInspection(x)}<br/></>)))}
                </div>)}
                {props.type === 'auction' && props.auctionDateTime && (<div className='card--upcoming-auction__row'>
                  <h3>Auction</h3>
                  {props.auctionDateTime && <p>{renderAuction()}</p>}
                </div>)}
              </div>
            </div>
          </div>
          <div className='card--upcoming__image-wrapper'>
            <Link to={props.permalink} className='card--upcoming__image'>
              {props.images?.length > 0 && props.images[0].primary?.length > 0 && (
                  <MediaItem item={props.images[0].primary}/>)}
            </Link>
            <Link to={props.permalink} className='card--upcoming__content'>
              <div className='card__title'>
                {!props.addressComponents && props.address && <h4>{props.address}</h4>}
                {props.addressComponents?.length > 0 && <h4>
                  <strong>{props.addressComponents[0]?.unit && `${props.addressComponents[0]?.unit} / `}
                    {props.addressComponents[0]?.streetNumber} {props.addressComponents[0]?.street + ''}</strong><br/>
                  {props.addressComponents[0]?.city}
                </h4>}
              </div>

              <div className='card__stats'>
                <ul>
                  {props.beds > 0 && <li><span>Bedrooms</span><span>{props.beds}</span></li>}
                  {props.baths > 0 && <li><span>Bathrooms</span><span>{props.baths}</span></li>}
                  {props.allCarSpaces > 0 && <li><span>Parking</span><span>{props.allCarSpaces}</span></li>}
                </ul>
              </div>
            </Link>
          </div>
          <div className='card--upcoming__button'>
            <div className='card--upcoming-auction'>
              <div className='card--upcoming-auction__auction'>
                <div className='card--upcoming-auction__rows'>
                  {props.type === 'inspection' && Array.isArray(props.inspections) && (<div className='card--upcoming-auction__row'>
                    <h3>Next Inspection</h3>
                    {props.inspections.filter(x => {
                      return new Date(x.startDateTimeUtc).getTime() > stableDateUnix
                    }).sort((a, b) => {
                      return new Date(a.startDateTimeUtc).getTime() - new Date(b.startDateTimeUtc).getTime()
                    }).slice(0, 1).map((x => (<>{renderInspection(x)}<br/></>)))}
                  </div>)}
                  {props.type === 'auction' && props.auctionDateTime && (<div className='card--upcoming-auction__row'>
                    <h3>Auction</h3>
                    {props.auctionDateTime && <p>{renderAuction()}</p>}
                  </div>)}
                </div>
              </div>
            </div>
            {props.permalink && <Link className='btn btn--black' to={props.permalink}>More <RightArrow/></Link>}
          </div>
        </div>
      </div>
  )
}

export default Upcoming